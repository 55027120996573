import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { Editor, NgxEditorModule } from 'ngx-editor';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-blog-editor-page',
  standalone: true,
  imports: [CommonModule, NgxEditorModule, FormsModule],
  templateUrl: './blog-editor.page.html',
  styleUrls: ['./blog-editor.page.scss'],
})
export class BlogEditorPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  editor: Editor;
  html: string = '';

  constructor(private injector: Injector) {
    super(injector);
    this.editor = new Editor();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
    this.editor.destroy();
  }
}
