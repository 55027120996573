import {
  Component,
  OnInit,
  Injector,
  Inject,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CommonModule } from '@angular/common';
import {
  Router,
  RouterOutlet,
  NavigationEnd,
  NavigationStart,
  Event,
} from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from 'src/components/header/header.component';
import { SpinnerComponent } from 'src/components/spinner/spinner.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from 'src/components/footer/footer.component';
import { Constants } from './app.constants';
import { BaseImports } from 'src/libs/base-imports';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    TranslateModule,
    HeaderComponent,
    SpinnerComponent,
    FooterComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseImports implements OnInit, OnDestroy {
  reservationInterval: ReturnType<typeof setInterval> | null = null;
  private _remainigReservationTime: string = '';
  subscriptions: Subscription[] = [];
  title = 'transportation-web-tickets';
  navigationEnd: Observable<NavigationEnd>;
  constructor(
    private injector: Injector,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    super(injector);
    this.navigationEnd = router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    this.subscriptions.push(
      this.sharedService.on(
        Constants.EV_RESERVATION_CREATED,
        this.startReservationTimeout.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  ngOnInit() {
    this.navigationEnd.subscribe((e: NavigationEnd) => {
      var url = e.url.split('/')[1];
      gtag('event', 'page_view', {
        page_title: url,
        page_path: e.urlAfterRedirects,
        page_location: this.document.location.href,
      });

      if (url == 'payment-status' || url == 'multiride-payment-status') {
        return;
      } else if (url == 'agency-ticket-preview') {
        this.stopReservationInterval();
      } else {
        this.webapiSessionService.isSessionOpen().subscribe((res) => {
          if (!res.IsSuccess) {
            this.routerService.navigate('closed-session');
          }
        });
      }
    });
    this.startReservationTimeout();

    const script = `
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/670ce41d4304e3196ad14342/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();`;
    const el = this.renderer.createElement('script');
    el.text = script;
    this.renderer.appendChild(document.body, el);
  }

  startReservationTimeout() {
    if (this.reservationInterval != null) {
      this.stopReservationInterval();
    }
    this.reservationInterval = setInterval(() => {
      var expiredInSeconds = this.storageService.getReservationDuration();
      if (expiredInSeconds == null) {
        this.stopReservationInterval();
        return;
      }
      if (expiredInSeconds <= 0) {
        this.storageService.clearTicket();
        this.routerService.navigate('index');
        if (expiredInSeconds > -3) {
          this.commonService.alertWarning('purchase_time_expired');
        }

        this.stopReservationInterval();
      } else {
        var rem_minutes = Math.floor(expiredInSeconds / 60);
        var rem_seconds = (expiredInSeconds - rem_minutes * 60) % 60;
        this._remainigReservationTime =
          rem_minutes +
          ':' +
          (rem_seconds < 10 ? '0' + rem_seconds : rem_seconds);

        this.sharedService.broadcast(
          Constants.EV_REMAINIG_RESERVATION_TIME,
          this._remainigReservationTime
        );
      }
    }, 1000);
  }

  stopReservationInterval() {
    if (!this.reservationInterval) {
      return;
    }
    clearInterval(this.reservationInterval);
    this.sharedService.broadcast(Constants.EV_PURCHASE_TIME_EXPIRED);
    this.reservationInterval = null;
    this._remainigReservationTime = '';
  }
}
