<h1 class="text-center">Blog List</h1>
<div class="d-flex flex-wrap gap-3 justify-content-center">
  <div *ngFor="let post of posts" class="card" style="width: 18rem">
    <!-- <img src="..." class="card-img-top" alt="..." /> -->
    <div class="card-body">
      <h3 class="card-title" [innerHTML]="post.title"></h3>
      <p [innerHTML]="post.excerpt"></p>
      <a [routerLink]="['/blog', post.slog]" class="btn btn-primary mt-auto"
        >Go somewhere</a
      >
    </div>
  </div>
</div>
