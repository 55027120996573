import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { ActivatedRoute, RouterModule } from '@angular/router';

@Component({
  selector: 'app-blog-page',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './blog-preview.page.html',
  styleUrls: ['./blog-preview.page.scss', '../../theme/blog.scss'],
})
export class BlogPreviewPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  posts: any[] = [];
  slug: string = '';
  constructor(private injector: Injector) {
    super(injector);
    this.slug = this.route.snapshot.params['slug'];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.http
        .get<any>(
          'https://wp.tremium.net/wp-json/wp/v2/posts?slug=' + this.slug,
          {}
        )
        .subscribe((res: any | any[]) => {
          console.log(res);
          this.posts = res; //.map((x: any) => x.content.rendered);
          //this.posts.push(res.content.rendered.replace(/(\r\n|\n|\r)/gm, ''));
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}
