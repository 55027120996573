import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseImports } from 'src/libs/base-imports';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';

interface Blog {
  title: string;
  slog: string;
  excerpt: string;
}

@Component({
  selector: 'app-blog-page',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './blog-list.page.html',
  styleUrls: ['./blog-list.page.scss', '../../theme/blog.scss'],
})
export class BlogListPage extends BaseImports implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  posts: Blog[] = [];

  constructor(private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.http
        .get<any>('https://wp.tremium.net/wp-json/wp/v2/posts', {})
        .subscribe((res: any | any[]) => {
          console.log(res);
          this.posts = res.map((x: any) => {
            return {
              title: x.title.rendered,
              excerpt: x.excerpt.rendered,
              slog: x.slug,
            } as Blog;
          });
          //this.posts.push(res.content.rendered.replace(/(\r\n|\n|\r)/gm, ''));
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }
}
