<div
  class="body post-template-default single single-post postid-12 single-format-standard logged-in admin-bar no-customize-support wp-embed-responsive"
>
  <div class="wp-site-blocks" *ngFor="let post of posts">
    <div
      class="main wp-block-group alignfull is-layout-flow wp-block-group-is-layout-flow"
      id="wp--skip-link--target"
    >
      <div
        class="wp-block-group has-global-padding is-layout-constrained wp-block-group-is-layout-constrained"
        style="
          margin-bottom: var(--wp--preset--spacing--40);
          padding-top: var(--wp--preset--spacing--50);
        "
      >
        <div
          class="wp-block-group is-vertical is-content-justification-stretch is-layout-flex wp-container-core-group-is-layout-8 wp-block-group-is-layout-flex"
        >
          <h1 class="wp-block-post-title has-x-large-font-size">
            {{post.title.rendered}}
          </h1>
          <div class="wp-block-template-part">
            <div
              class="wp-block-group has-global-padding is-layout-constrained wp-block-group-is-layout-constrained"
            >
              <div
                class="wp-block-group is-content-justification-left is-layout-flex wp-container-core-group-is-layout-6 wp-block-group-is-layout-flex"
              >
                <div class="wp-block-post-date">
                  <time datetime="2024-10-18T08:19:14+00:00"
                    ><a
                      href="https://wp.tremium.net/2024/10/18/kupovina-karata-u-aleksincu/"
                      >Oct 18, 2024</a
                    ></time
                  >
                </div>
                <p class="has-contrast-2-color has-text-color">—</p>

                <p
                  class="has-small-font-size has-contrast-2-color has-text-color"
                >
                  by
                </p>
                <div class="wp-block-post-author-name">
                  <a
                    href="https://wp.tremium.net/author/admin/"
                    target="_self"
                    class="wp-block-post-author-name__link"
                    >admin</a
                  >
                </div>

                <div class="taxonomy-category wp-block-post-terms">
                  <span class="wp-block-post-terms__prefix">in </span
                  ><a
                    href="https://wp.tremium.net/category/uncategorized/"
                    rel="tag"
                    >Uncategorized</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [innerHTML]="post?.content?.rendered"
        class="entry-content alignfull wp-block-post-content has-global-padding is-layout-constrained wp-block-post-content-is-layout-constrained"
      ></div>
    </div>
  </div>
</div>

<script
  src="https://wp.tremium.net/wp-includes/js/hoverintent-js.min.js?ver=2.2.1"
  id="hoverintent-js-js"
></script>
<script
  src="https://wp.tremium.net/wp-includes/js/admin-bar.min.js?ver=6.6.2"
  id="admin-bar-js"
></script>
<script
  src="https://wp.tremium.net/wp-includes/js/comment-reply.min.js?ver=6.6.2"
  id="comment-reply-js"
  async
  data-wp-strategy="async"
></script>
<script id="wp-block-template-skip-link-js-after">
  (function () {
    var skipLinkTarget = document.querySelector(".main"),
      sibling,
      skipLinkTargetID,
      skipLink;

    // Early exit if a skip-link target can't be located.
    if (!skipLinkTarget) {
      return;
    }

    /*
     * Get the site wrapper.
     * The skip-link will be injected in the beginning of it.
     */
    sibling = document.querySelector(".wp-site-blocks");

    // Early exit if the root element was not found.
    if (!sibling) {
      return;
    }

    // Get the skip-link target's ID, and generate one if it doesn't exist.
    skipLinkTargetID = skipLinkTarget.id;
    if (!skipLinkTargetID) {
      skipLinkTargetID = "wp--skip-link--target";
      skipLinkTarget.id = skipLinkTargetID;
    }

    // Create the skip link.
    skipLink = document.createElement("a");
    skipLink.classList.add("skip-link", "screen-reader-text");
    skipLink.href = "#" + skipLinkTargetID;
    skipLink.innerHTML = "Skip to content";

    // Inject the skip link.
    sibling.parentElement.insertBefore(skipLink, sibling);
  })();
</script>
