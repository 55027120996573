import { Routes } from '@angular/router';
import { IndexPage } from 'src/pages/index/index.page';
import { SignUpPage } from 'src/pages/sign-up/sign-up.page';
import { ForgotPasswordPage } from 'src/pages/forgot-password/forgot-password.page';
import { JourneysPage } from 'src/pages/journeys/journeys.page';
import { ProfilePage } from 'src/pages/profile/profile.page';
import { ChangePasswordPage } from 'src/pages/change-password/change-password.page';
import { MultiridePassengerListPage } from 'src/pages/multiride-passenger-list/multiride-passenger-list.page';
import { TicketCreatePage } from 'src/pages/ticket-create/ticket-create.page';
import { TicketPreviewPage } from 'src/pages/ticket-preview/ticket-preview.page';
import { ReservationsCreatePage } from 'src/pages/reservation-create/reservation-create.page';
import { AgencyTicketPreviewPage } from 'src/pages/agency-ticket-preview/agency-ticket-preview.page';
import { PaymentStatusPage } from 'src/pages/payment-status/payment-status.page';
import { AgencyReportPage } from 'src/pages/agency-report/agency-report.page';
import { UserTicketsPreviewPage } from 'src/pages/user-tickets-preview/user-tickets-preview.page';
import { OpenJourneyTicketCreatePage } from 'src/pages/open-journey-ticket-create/open-journey-ticket-create.page';
import { MultirideCreatePage } from 'src/pages/multiride-create/multiride-create.page';
import { MultiridePreviewPage } from 'src/pages/multiride-preview/multiride-preview.page';
import { MultiridePaymentStatusPage } from 'src/pages/multiride-payment-status/multiride-payment-status.page';
import { ClosedSessionPage } from 'src/pages/closed-session/closed-session.page';
import { BlogPreviewPage } from 'src/pages/blog-preview/blog-preview.page';
import { BlogEditorPage } from 'src/pages/blog-editor/blog-editor.page';
import { BlogListPage } from 'src/pages/blog-list/blog-list.page';

export const routes: Routes = [
  { path: 'index', component: IndexPage /*, canActivate: [PublicGuard]*/ },
  { path: 'sign-up', component: SignUpPage /*, canActivate: [PublicGuard]*/ },
  { path: 'profile', component: ProfilePage /*, canActivate: [PublicGuard]*/ },
  {
    path: 'blog/:slug',
    component: BlogPreviewPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'blog-editor',
    component: BlogEditorPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'blog-list',
    component: BlogListPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'change-password',
    component: ChangePasswordPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'ticket-create',
    component: TicketCreatePage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'ticket-preview',
    component: TicketPreviewPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'reservation-create',
    component: ReservationsCreatePage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'agency-ticket-preview',
    component: AgencyTicketPreviewPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'journeys/:startpoint/:endpoint/:date',
    component: JourneysPage /*, canActivate: [PublicGuard]*/,
  },
  {
    path: 'multiride-passenger-list',
    component: MultiridePassengerListPage /*, canActivate: [PublicGuard]*/,
  },
  { path: 'multiride-create/:passId', component: MultirideCreatePage },
  { path: 'multiride-preview', component: MultiridePreviewPage },
  {
    path: 'agency-report',
    component: AgencyReportPage /*, canActivate: [PublicGuard]*/,
  },
  { path: 'user-tickets-preview', component: UserTicketsPreviewPage },
  {
    path: 'open-journey-ticket-create',
    component: OpenJourneyTicketCreatePage,
  },
  { path: 'closed-session', component: ClosedSessionPage },
  {
    path: 'multiride-payment-status/:isSuccess/:transId/:authCode/:orderId/:datetime/:mdStatus/:procReturnCode/:response',
    component: MultiridePaymentStatusPage,
  },
  {
    path: 'payment-status/:isSuccess/:transId/:authCode/:orderId/:datetime/:mdStatus/:procReturnCode/:response',
    component: PaymentStatusPage,
  },
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: '**', redirectTo: 'index', pathMatch: 'full' },
];
